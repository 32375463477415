import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelBrandDetail } from '~/schemes';

import { HImg } from '~/components';
import { MyHotelInstantPhoto } from '~/pages/_lang/hotels/_hotel_slug/index/-components/MyHotelInstantPhoto';

export interface MyBrandFeatureProps {
  data: HotelBrandDetail;
}

export interface MyBrandFeatureEmits {}

export interface MyBrandFeatureScopedSlots {}

@Component<MyBrandFeatureRef>({
  name: 'MyBrandFeature',
  render() {
    const { brand } = this;
    const { features } = brand;

    return (
      <div staticClass="my-features">
        {!this.$theme.is('hoshinoya') && (
          <h2 staticClass="my-features__title" v-wysiwyg={features.title} />
        )}
        <ul staticClass="my-features__items">
          {features.items.map((item, index) => (
            <li key={index} staticClass="my-features__item">
              {this.$theme.is('lucy') ? (
                <div staticClass="my-features__item__image-wrapper">
                  <MyHotelInstantPhoto
                    src={this.$res.img(item.image)}
                    stackTiltAngle={index % 2 === 0 ? -4 : 4}
                    axis="horizontal"
                    style={{
                      '--photo-width': `${
                        this.$mq.match.narrow ? 'calc(100vw - 48px)' : '50vw'
                      }`,
                      'min-width': `${
                        this.$mq.match.narrow ? 'none' : '253px'
                      }`,
                    }}
                  />
                </div>
              ) : (
                <HImg
                  staticClass="my-features__item__image"
                  src={this.$res.img(item.image)}
                  inview
                  liquid
                  corner
                />
              )}
              <div staticClass="my-features__item__body">
                <h3 staticClass="my-features__item__title">{item.title}</h3>
                <div
                  staticClass="my-features__item__content"
                  v-wysiwyg={item.content}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  },
})
export default class MyBrandFeatureRef
  extends Vue
  implements MyBrandFeatureProps {
  @Prop({ type: Object, required: true }) readonly data!: HotelBrandDetail;

  get brand() {
    return this.data;
  }
}

export const TypedMyBrandFeature = tsx
  .ofType<MyBrandFeatureProps, MyBrandFeatureEmits, MyBrandFeatureScopedSlots>()
  .convert(MyBrandFeatureRef);
