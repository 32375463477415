import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { gsap } from 'gsap';
import BrandViewRoot from '../../../-index';
import { MyBrandConceptMovie } from './MyBrandConceptMovie';
import { HotelBrandDetail } from '~/schemes';
import { BrandConceptMovie } from '~/schemes/brand';

import { HSpriteAnimation } from '~/components';
import { MyHotelInstantPhoto } from '~/pages/_lang/hotels/_hotel_slug/index/-components/MyHotelInstantPhoto';

export interface MyBrandSectionHeaderProps {
  data: HotelBrandDetail;
}

export interface MyBrandSectionHeaderEmits {}

export interface MyBrandSectionHeaderScopedSlots {}

@Component<MyBrandSectionHeaderRef>({
  name: 'MyBrandSectionHeader',
  inject: ['brandViewRoot'],
  mounted() {
    // テーマが「LUCY」の時のみ実行
    if (this.$theme.is('lucy')) {
      if (!process.client) return;

      /**
       * 指示されたアニメーションを実現するため、<MyHotelInstantPhoto/> の my-hotel-instant-photo__inner をピンポイントでアニメーション対象に指定する
       * @see https://chot-inc.slack.com/archives/C07KNT3UZME/p1740733547514409?thread_ts=1740733308.679819&cid=C07KNT3UZME
       */
      const photos = document.querySelectorAll(
        '.my-header__tilted-photos-photo > .my-hotel-instant-photo__inner',
      );
      const numberOfPhotos = photos.length;

      // メインとサブのキービジュアル画像が合計2枚以上ないとアニメーションの意味がないので早期リターン
      if (numberOfPhotos < 2) return;

      const duration = 1; // 要素間の遷移時間 (秒)
      const pause = 4; // アニメーションの停止時間（秒）
      const stagger = duration + pause; // 1要素にかかる時間
      const repeatDelay = stagger * (numberOfPhotos - 1) + pause; // 1サイクルの時間
      const offsetX = -10; // X軸の初期位置移動距離
      const offsetY = -10; // Y軸の初期位置移動距離

      const timeline = gsap.timeline();
      timeline
        .from(photos, {
          x: offsetX,
          y: offsetY,
          duration,
          opacity: 0,
          stagger: {
            each: stagger,
            repeat: -1,
            repeatDelay,
          },
        })
        .to(
          photos,
          {
            x: 0,
            y: 0,
            duration,
            opacity: 0,
            stagger: {
              each: stagger,
              repeat: -1,
              repeatDelay,
            },
          },
          stagger,
        );

      gsap.set('.my-header__tilted-photos', { autoAlpha: 1 });
    }
  },
  render() {
    const {
      motionLogo,
      brand,
      computedHotels: hotels,
      conceptMovie,
      brandName,
      // isInviewEnabled,
    } = this;
    const { catchCopy, mainLogo, keyVisual, lead } = brand;

    const countString = this.$t('value.numOfHotels', {
      count: hotels.length,
    }) as string;

    // const viewProps = isInviewEnabled
    //   ? {
    //       directives: [
    //         {
    //           name: 'inview',
    //           value: {
    //             in: () => {
    //               this.brandViewRoot.pageTopScrollActiveSectionId = 'header';
    //             },
    //             rootMargin: '-100px',
    //           },
    //         },
    //       ],
    //     }
    //   : {};

    if (this.$theme.is('hoshinoya')) {
      return (
        // <div staticClass="my-header" {...viewProps}>
        <div staticClass="my-header">
          <div staticClass="my-header__inner">
            <h1 staticClass="my-header__logo">
              {!motionLogo ? (
                <img
                  key="static-logo"
                  staticClass="my-header__logo__img"
                  // オープニングアニメーション
                  // class={{
                  //   'my-header__logo__img--loaded': this.headLogoLoaded,
                  // }}
                  alt={brand.name}
                  src={this.$res.img(`/brands/${brand.slug}/logo-header.svg`)}
                />
              ) : (
                <HSpriteAnimation
                  key="sprite-logo"
                  staticClass="my-header__logo my-header__logo--sprite"
                  src={motionLogo.url}
                  fallback={motionLogo.fallback}
                  width={motionLogo.width}
                  height={motionLogo.height}
                  fps={motionLogo.fps}
                  duration={motionLogo.duration}>
                  {brand.name}
                </HSpriteAnimation>
              )}
            </h1>
            <div staticClass="my-header__catch-copy" v-wysiwyg={catchCopy} />
            <div
              staticClass="my-header__num-of-hotels theme-secondary-text-color"
              domPropsInnerHTML={countString}
            />

            <div
              staticClass="my-header__key_visual"
              style={{
                backgroundImage: `url(${this.$res.img(keyVisual.image)})`,
              }}
            />
            {/* アニメーション
            <div class="my-header__logo__key_visual__shutter-up"></div>
            <div class="my-header__logo__key_visual__shutter-down"></div> */}
          </div>

          <div staticClass="my-header__lead" v-wysiwyg={lead} />

          {conceptMovie && <MyBrandConceptMovie conceptMovie={conceptMovie} />}
        </div>
      );
    } else if (this.$theme.is('lucy')) {
      return (
        <div staticClass="my-header">
          <div staticClass="my-header__hero-wrapper">
            <div staticClass="my-header__description">
              <h1 staticClass="my-header__title">
                <img
                  key="static-logo"
                  staticClass="my-header__logo"
                  src={this.$res.img(mainLogo.url)}
                  alt={brand.name}
                />
              </h1>
              {/* 施設数 */}
              <div
                staticClass="my-header__num-of-hotels theme-secondary-text-color"
                domPropsInnerHTML={countString}
              />
              {/* キャッチコピー */}
              <div staticClass="my-header__catch-copy" v-wysiwyg={catchCopy} />
              {/* リード文 */}
              <div staticClass="my-header__lead" v-wysiwyg={lead} />
            </div>
            {/* キービジュアル */}
            {keyVisual.image !== '' && (
              <div staticClass="my-header__tilted-photos">
                <MyHotelInstantPhoto
                  staticClass="my-header__tilted-photos-photo"
                  src={this.$res.img(keyVisual.image)}
                  caption={keyVisual.alt}
                  axis="vertical"
                  stackTiltAngle={4}
                  style={{
                    '--photo-width': `${
                      this.$mq.match.narrow ? '75vw' : '40vw'
                    }`,
                  }}
                  // サブキービジュアル画像がある場合はサブキービジュアルの最後の写真だけモック画像を下に重ねる都合上、
                  // メインのキービジュアル画像はモック画像を重ねない。
                  isStack={
                    brand.subKeyVisuals && brand.subKeyVisuals.length === 0
                  }
                />
                {brand.subKeyVisuals &&
                  brand.subKeyVisuals.map(
                    (subKeyVisual, index, subKeyVisuals) => (
                      <MyHotelInstantPhoto
                        staticClass="my-header__tilted-photos-photo"
                        src={this.$res.img(subKeyVisual.derived.main.url)}
                        caption={subKeyVisual.meta && subKeyVisual.meta.caption}
                        axis="vertical"
                        stackTiltAngle={4}
                        style={{
                          '--photo-width': `${
                            this.$mq.match.narrow ? '75vw' : '40vw'
                          }`,
                        }}
                        // 最後の要素の場合だけモック画像を下に重ねることで、
                        // 「モック画像を一番下に敷く」 & 「アニメーション中常に固定」を可能にする
                        isStack={subKeyVisuals.length === index + 1}
                      />
                    ),
                  )}
              </div>
            )}
          </div>
          {/* ブランドコンセプトムービー */}
          {conceptMovie && <MyBrandConceptMovie conceptMovie={conceptMovie} />}
        </div>
      );
    } else {
      return (
        <div staticClass="my-header">
          <h1 staticClass="my-header__title">
            {!motionLogo ? (
              <img
                key="static-logo"
                staticClass="my-header__logo"
                src={this.$res.img(mainLogo.url)}
                alt={brand.name}
              />
            ) : (
              <HSpriteAnimation
                key="sprite-logo"
                staticClass="my-header__logo my-header__logo--sprite"
                src={motionLogo.url}
                fallback={motionLogo.fallback}
                width={motionLogo.width}
                height={motionLogo.height}
                fps={motionLogo.fps}
                duration={motionLogo.duration}>
                {brand.name}
              </HSpriteAnimation>
            )}
          </h1>

          {this.isOtherTheme() && (
            <div staticClass="my-header__brand-name" v-wysiwyg={brandName} />
          )}

          {/* キャッチコピー */}
          <div staticClass="my-header__catch-copy" v-wysiwyg={catchCopy} />
          {/* 施設数 */}
          <div
            staticClass="my-header__num-of-hotels theme-secondary-text-color"
            domPropsInnerHTML={countString}
          />
          {/* キービジュアル */}
          <div
            staticClass="my-header__key_visual"
            style={{
              backgroundImage: `url(${this.$res.img(keyVisual.image)})`,
            }}>
            <div staticClass="my-header__key_visual__alt">{keyVisual.alt}</div>
          </div>
          {/* リード文 */}
          <div staticClass="my-header__lead" v-wysiwyg={lead} />
          {/* ブランドコンセプトムービー */}
          {conceptMovie && <MyBrandConceptMovie conceptMovie={conceptMovie} />}
        </div>
      );
    }
  },
})
export default class MyBrandSectionHeaderRef
  extends Vue
  implements MyBrandSectionHeaderProps {
  readonly brandViewRoot!: BrandViewRoot;
  @Prop({ type: Object, required: true }) readonly data!: HotelBrandDetail;

  get brand() {
    return this.data;
  }

  get mainLogo() {
    return this.data.mainLogo;
  }

  get motionLogo() {
    const motionLogo = this.data.mainLogo.sprite;
    if (motionLogo) {
      const { url, fallback, webp } = motionLogo;
      return {
        ...motionLogo,
        url: webp ? this.$res.webp(url) : this.$res.img(url),
        fallback: fallback && this.$res.img(fallback),
      };
    }
  }

  get computedHotels() {
    return this.hotels
      .map((hotel) => {
        return {
          ...hotel,
          location: this.$hotel.toNavigationProps(hotel)!,
        };
      })
      .filter((h) => !!h.location);
  }

  get hotels() {
    return this.brandViewRoot.hotels;
  }

  get conceptMovie(): BrandConceptMovie | null {
    return this.data.conceptMovie;
  }

  /** コンセプトムービータイトル */
  get title(): string {
    if (!this.conceptMovie) return '';
    const { title } = this.conceptMovie;
    if (!title) return '';
    return this.$language.current === 'ja' ? `${title}を見る` : title;
  }

  get brandName() {
    return this.$theme.is('uniquehotels')
      ? `そのほかの</br>個性的な宿泊施設`
      : '日帰り施設';
  }

  /** テーマが「そのほかの個性的なホテル」または「日帰り施設」かどうかを返却 */
  private isOtherTheme(): boolean {
    return this.$theme.is('uniquehotels') || this.$theme.is('daytrip');
  }

  // // アニメーションの設定
  // private headLogoLoaded: boolean = false;
  // /** 星のやの時、OPの発火に関するのプロパティを設置 */
  // get isInviewEnabled() {
  //   return this.$theme.is('hoshinoya');
  // }
}

export const TypedMyBrandSectionHeader = tsx
  .ofType<
    MyBrandSectionHeaderProps,
    MyBrandSectionHeaderEmits,
    MyBrandSectionHeaderScopedSlots
  >()
  .convert(MyBrandSectionHeaderRef);
