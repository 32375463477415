import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelBrandPromises } from '~/schemes';

export interface MyBrandPromisesProps {
  brandPromises: HotelBrandPromises;
}

export interface MyBrandPromisesEmits {}

export interface MyBrandPromisesScopedSlots {}

/** ブランドの約束 LUCYだけ */
@Component<MyBrandPromisesRef>({
  name: 'MyBrandPromises',
  render() {
    const { title, subtitle, promises } = this.brandPromises;

    return (
      <div staticClass="my-promises">
        <div staticClass="my-promises__inner">
          <div staticClass="my-promises__titles">
            <h2 staticClass="my-promises__titles__title">{title}</h2>
            <h3 staticClass="my-promises__titles__subtitle">{subtitle}</h3>
          </div>
          <div staticClass="my-promises__items">
            <ol staticClass="my-promises__items__list">
              {promises.map((promise) => (
                <li staticClass="my-promises__items__list-item">
                  <img
                    staticClass="my-promises__items__list-item-image"
                    key="static-logo"
                    src={this.$res.img(promise.imageSrc)}
                    alt={promise.title}
                  />
                  <p staticClass="my-promises__items__list-item-title">
                    {promise.title}
                  </p>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    );
  },
})
export default class MyBrandPromisesRef
  extends Vue
  implements MyBrandPromisesProps {
  @Prop({ type: Object, required: true })
  readonly brandPromises!: HotelBrandPromises;
}

export const TypedMyBrandPromises = tsx
  .ofType<
    MyBrandPromisesProps,
    MyBrandPromisesEmits,
    MyBrandPromisesScopedSlots
  >()
  .convert(MyBrandPromisesRef);
