import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelBrandOrigin } from '~/schemes';

export interface MyBrandOriginProps {
  brandOrigin: HotelBrandOrigin;
}

export interface MyBrandOriginEmits {}

export interface MyBrandOriginScopedSlots {}

/** ブランドオリジン LUCYだけ */
@Component<MyBrandOriginRef>({
  name: 'MyBrandOrigin',
  render() {
    const { title, description, logo } = this.brandOrigin;

    return (
      <div staticClass="my-origin">
        <div staticClass="my-origin__inner">
          <div staticClass="my-origin__logo">
            <img
              key="static-logo"
              src={this.$res.img(logo.url)}
              alt={logo.alt}
            />
          </div>
          <div staticClass="my-origin__contents">
            <h2 staticClass="my-origin__contents__title">{title}</h2>
            <div staticClass="my-origin__contents__description">
              {description}
            </div>
          </div>
        </div>
      </div>
    );
  },
})
export default class MyBrandOriginRef
  extends Vue
  implements MyBrandOriginProps {
  @Prop({ type: Object, required: true })
  readonly brandOrigin!: HotelBrandOrigin;
}

export const TypedMyBrandOrigin = tsx
  .ofType<MyBrandOriginProps, MyBrandOriginEmits, MyBrandOriginScopedSlots>()
  .convert(MyBrandOriginRef);
